import type { ClassNameProp } from '@charterindex/armoury-www';
import Box from '@mui/material/Box';
import { DateRangeIcon, DateRangePicker } from '@mui/x-date-pickers-pro';
import { add } from 'date-fns';
import { useSubject } from 'ecce-react';
import useTranslation from 'next-translate/useTranslation';
import { useEnquiryController } from '../../enquiry-controller-context';


export const EnquiryFormDatesField = ({ className }: ClassNameProp) => {
	const controller = useEnquiryController();
	useSubject(controller.model, 'dates');
	useSubject(controller, 'submitting');

	const { t } = useTranslation();

	return (
		<Box className={className} display="grid" width="100%">
			<DateRangePicker
				value={controller.model.dates}
				onChange={value => controller.model.dates = value}
				minDate={add(Date.now(), { days: 1 })}
				localeText={{
					start: t('common:start-date'),
					end: t('common:end-date'),
				}}
				slotProps={{
					fieldSeparator: {
						sx: { display: 'none' },
					},
					textField: {
						size: 'small',
						InputProps: {
							startAdornment: <DateRangeIcon/>,
						},
					},
				}}
			/>
		</Box>
	);
};
