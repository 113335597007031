import type { RelatedYachts } from '../../../domain';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { YachtCardCarousel } from '../../components';


const RelatedYachtsCarouselsRoot = styled('section')(({ theme }) => css`
	--card-spacing: calc(var(--page-pad-y) / 2);
	--padding: 0 calc(var(--card-spacing)) 0 calc(var(--card-spacing) * 2);
	--num-cards: 1;
	--card-width: calc((100% / var(--num-cards)) - var(--card-spacing));
	
	width: 100vw;
	max-width: calc(100vw - ${theme.spacing(1)});
	margin-right: auto;
	padding: var(--page-pad-y) 0;

	background-color: #fafafa;
	box-shadow: inset 0 ${theme.spacing(.25)} ${theme.spacing(.5)} #8a8a8a16;
	
	.related-yachts-section {
		> * {
			padding: var(--padding);
		}

		& + * {
			margin-top: ${theme.spacing(3)};
		}
	}
`);

export type RelatedYachtsCarouselsProps = {
	initialRelatedYachts?: RelatedYachts[];
};
export const RelatedYachtsCarousels = ({ initialRelatedYachts }: RelatedYachtsCarouselsProps) => {
	const { t } = useTranslation();
	const router = useRouter();
	const api = useApi();

	const [ relatedYachts, setRelatedYachts ] = useState(initialRelatedYachts);

	useEffect(() => {
		const uri = router.locale + router.asPath;
		api.relatedYachts(uri)
			.then(freshRelatedYachts => {
				if(freshRelatedYachts?.length) {
					setRelatedYachts(freshRelatedYachts);
				}
			});
	}, [api, router.locale, router.asPath]);

	if(!relatedYachts?.length) {
		return null;
	}

	return (
		<RelatedYachtsCarouselsRoot>
			{ relatedYachts.map(section => (
				<div className="related-yachts-section" key={section.title}>
					<Typography variant="h2">
						{ t(`common:${section.title}`) }
					</Typography>

					{ section.relatedYachts.map(({ id, subTitle, yachts }) => !!yachts.length && (
						<div className="related-yacht-list" key={id}>
							<Typography className="related-yacht-list__title" variant="h3">
								{ subTitle }
							</Typography>
							<YachtCardCarousel
								className="related-yacht-list__carousel"
								name="related-yachts"
								yachts={yachts}
								numCards={{
									sm: 2,
									md: 3,
									lg: 4,
									xl: 5,
								}}
							/>
						</div>
					)) }
				</div>
			))}
		</RelatedYachtsCarouselsRoot>
	);
};
